import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Box } from '@material-ui/core';
import Header from '../../components/header/Header';
import Loading from '../../components/common/Loading';
import { autologin } from '../../api/selfieWebService';
import useQueryParam from '../../customHook/useQueryParam';
import {
  removeCurrentLoginData, removeCurrentUser, removeCustomerAccountStatus, saveCurrentUser, saveCustomerAccountStatus,
} from '../../api/userStorage';
import { useCustomerAccountStatus } from '../../contexts/CustomerAccountStatusContext';
import { useAuth } from '../../contexts/AuthContext';
import useHomeNavigation from '../../customHook/useHomeNavigation';
import { PublicRoutesList, getPathRouteFromKey } from '../../utils/menuAndRoutesUtil';
import i18n from '../../common/i18n';
import {
  processLogin, resolveCustomBannerList, resolveCustomerAccountStatus, resolvePrepaidBenefit,
  resolveLastPendingLoan, resolveNewLoanOfferIfApplies,
} from '../../utils/loginUtil';

const Autologin = () => {
  const queryParam = useQueryParam();
  const { setCustomerAccountStatus } = useCustomerAccountStatus();
  const { setCurrentUser } = useAuth();
  const history = useHistory();
  const goHome = useHomeNavigation();

  const goToHome = () => {
    goHome();
  };

  const goToManualLogin = () => {
    history.push({
      pathname: PublicRoutesList.loginDNIValidationStep,
      state: {
        message: i18n.autologinError,
      },
    });
  };

  const init = async () => {
    try {
      // IMPORTANTE: Parte de esta logica tambien se encuentra en loginVerificationCode.jsx.
      // Parte de la logica en comun se extrajo en loginUtil.jsx
      // (algunas cosas no pudieron extraerse, ya que hooks y state son por componente)
      // Si se agrega algun dato al storage o contexto durante el login, asegurarse de que impacte en ambos casos.

      // Realiza autologin
      const authenticateCustomerResponse = await autologin(queryParam.get('data'));
      // procesa la info
      let userAuth = processLogin(authenticateCustomerResponse, queryParam.get('gender'));

      const prepaidBenefit = await resolvePrepaidBenefit(userAuth);
      const customBannerList = await resolveCustomBannerList();
      const lastPendingLoan = await resolveLastPendingLoan(userAuth);
      userAuth = {
        ...userAuth, prepaidBenefit, customBannerList, lastPendingLoan,
      };
      // guarda la info en localstorage
      saveCurrentUser(userAuth);

      // resuelve el estado de cuenta
      const customerAccountStatusData = await resolveCustomerAccountStatus(userAuth);
      if (customerAccountStatusData !== undefined) {
        // guarda toda la info en el localstorage y contexto
        saveCustomerAccountStatus(customerAccountStatusData);
        setCustomerAccountStatus(customerAccountStatusData);
        removeCurrentLoginData();
        setCurrentUser(userAuth); // cambia el estado del contexto y la ruta publica redirige a la HOME
        window.location = authenticateCustomerResponse.data.redirectTo;

        // buscar oferta nueva
        const loanOffer = await resolveNewLoanOfferIfApplies(userAuth);
        if (loanOffer) {
          customerAccountStatusData.loanOffer = loanOffer;
          // actualiza la info en el localstorage y contexto
          saveCustomerAccountStatus(customerAccountStatusData);
          setCustomerAccountStatus(customerAccountStatusData);
          // si estoy en el home, hace refresh
          if (window.location.pathname === getPathRouteFromKey(i18n.homeKey)) {
            goToHome();
          }
        }
      } else {
        goToManualLogin();
      }
    } catch (error) {
      removeCustomerAccountStatus();
      removeCurrentUser();
      goToManualLogin();
    }
  };

  useEffect(() => init(), []);

  return (
    <>
      <Header />
      <Box m={6} mt={16} />
      <Loading />
    </>
  );
};

export default Autologin;
