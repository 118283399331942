import { Grid, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import i18n from '../common/i18n';
import { generatePasswordSchemaValidation } from '../forms/schemaValidations/schemaValidations';
import LoadingButton from '../components/common/LoadingButton';
import MessageResponse from '../components/common/MessageResponse';
import { AUTHENTICATE_SET_CREDENCIALS_ERROR, AUTHENTICATE_SET_CREDENCIALS_SUCCESS } from '../utils/referenceConstant';
import PasswordInput from '../components/common/PasswordInput';
import useGeneratePasswordController from '../customHook/controller/useGeneratePasswordController';

const GeneratePassword = () => {
  const {
    loading, generatePassword, responseSuccess, responseError, onContinue,
  } = useGeneratePasswordController();
  const methods = useForm({
    resolver: yupResolver(generatePasswordSchemaValidation),
  });

  if (responseSuccess) {
    return (
      <MessageResponse
        response={responseSuccess}
        referenceLabels={AUTHENTICATE_SET_CREDENCIALS_SUCCESS}
        onSuccessPrimary={onContinue}
        successGreen
      />
    );
  }

  if (responseError) {
    return (
      <MessageResponse
        response={responseError}
        referenceLabels={AUTHENTICATE_SET_CREDENCIALS_ERROR}
        customImgError="/images/warning-red.svg"
      />
    );
  }

  return (
    <>
      <Grid container justify="center">
        <Grid item xs={10} sm={10} md={8}>
          <Typography variant="h2" component="h1" align="left" style={{ marginTop: '50px' }}>
            {i18n.GeneratePassword.title}
          </Typography>
          <Typography align="left" style={{ marginTop: '8px' }}>
            {i18n.GeneratePassword.description}
          </Typography>
          <Alert severity="info" style={{ marginTop: '24px' }}>
            <Typography align="left">{i18n.GeneratePassword.warning}</Typography>
          </Alert>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(generatePassword)}>
              <PasswordInput
                name="newPass"
                label={i18n.GeneratePassword.inputPass}
                fullWidth
                mandatory
                style={{ marginTop: '32px' }}
              />
              <PasswordInput
                name="repeatNewPass"
                label={i18n.GeneratePassword.inputRepeatPass}
                fullWidth
                mandatory
                style={{ marginTop: '32px' }}
              />
              <LoadingButton
                variant="contained"
                color="primary"
                type="submit"
                style={{ width: '100%', marginTop: '32px' }}
                loading={loading}
              >
                {i18n.GeneratePassword.cta}
              </LoadingButton>
            </form>
          </FormProvider>
        </Grid>
      </Grid>
    </>
  );
};

export default GeneratePassword;
