import { useEffect, useState } from 'react';
import {
  ListItem, ListItemIcon, ListItemText, makeStyles, Typography, Button,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import parse from 'html-react-parser';
import Box from '@material-ui/core/Box';
import i18n from '../common/i18n';
import { moneyFormatter } from '../utils/formatterUtil';
import { getPathRouteFromKey } from '../utils/menuAndRoutesUtil';
import { useCustomerAccountStatus } from '../contexts/CustomerAccountStatusContext';
import imgTarjetaLineaCredito from '../assets/imgs/img_tarjeta_linea_credito.svg';
import imgDeudaLineaCredito from '../assets/imgs/img_deuda_linea_credito.svg';
import imgBack from '../assets/imgs/atras.svg';
import { fillMessageWith } from '../utils/functionsUtil';

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    padding: '25px',
  },
  headerSubtitle: {
    fontWeight: '600',
    color: 'white',
  },
  listBullet: {
    color: '#29CC6A',
    fontSize: '20px',
  },
  listText: {
    color: '#000000de',
  },
  link: {
    padding: '0px',
    textDecoration: 'underline',
    paddingBottom: '3px',
  },
  arrowBack: {
    fontWeight: '700',
  },
}));

const RevolvingLineOffer = () => {
  const history = useHistory();
  const classes = useStyles();
  const [showHelp, setShowHelp] = useState(false);

  const {
    customerAccountStatus: {
      customerRevolvingLine = null,
      customerIsUpToDate = true,
    } = {},
  } = useCustomerAccountStatus() || {};

  const goToPay = () => {
    history.push(getPathRouteFromKey(i18n.payInstallmentKey));
  };

  const goToNewLoan = () => {
    history.push(`${getPathRouteFromKey(i18n.newLoanKey)}?product=46&subproduct=95`);
  };

  const init = () => {
    // si ya tiene linea de credito creada
    if (customerRevolvingLine?.maxAmount) {
      history.push(`${getPathRouteFromKey(i18n.revolvingLineDetail)}`);
    }
  };

  useEffect(() => { init(); }, []);

  return (
    <>
      {showHelp ? (
        <>
          <Box component="header" className={classes.header}>
            <Box m={1}>
              <Typography variant="h1">{i18n.RevolvingLine.Offer.headerTitle}</Typography>
            </Box>
          </Box>

          <Box m={4}>
            <Typography variant="h2">
              {i18n.RevolvingLine.Offer.helpTitle}
            </Typography>
          </Box>

          <Box m={4} textAlign="left">
            <Typography aling="left">
              {parse(i18n.RevolvingLine.Offer.helpText)}
            </Typography>
          </Box>

          <Box m={1} textAlign="left">
            <ul>
              <li className={classes.listBullet} style={{ color: '#212121' }}>
                <Typography className={classes.listText}>
                  {parse(i18n.RevolvingLine.Offer.helpListItemOne)}
                </Typography>
              </li>
              <Box m={2} />
              <li className={classes.listBullet} style={{ color: '#212121' }}>
                <Typography className={classes.listText}>
                  {parse(i18n.RevolvingLine.Offer.helpListItemTwo)}
                </Typography>
              </li>
            </ul>
          </Box>

          <Box m={8} />

          <Box m={4}>
            <Button
              variant="tertiary"
              onClick={() => setShowHelp(false)}
            >
              <ListItem style={{ padding: '0px' }}>
                <ListItemIcon style={{ minWidth: '24px' }}>
                  <img src={imgBack} alt="back" width="20px" />
                </ListItemIcon>
                <ListItemText
                  primary={(
                    <Typography className={classes.arrowBack}>
                      {i18n.RevolvingLine.Offer.helpArrowBack}
                    </Typography>
                  )}
                />
              </ListItem>
            </Button>
          </Box>

        </>
      ) : (
        <>
          <Box component="header" className={classes.header}>
            <Typography variant="h1">{i18n.RevolvingLine.Offer.headerTitle}</Typography>
          </Box>
          {customerIsUpToDate === false ? (
            <>
              <Box m={3}>
                <img src={imgDeudaLineaCredito} width="100px" alt="img linea de crédito" />
              </Box>
              <Box m={2}>
                <Typography variant="h2">
                  {i18n.RevolvingLine.Offer.customerInDebtTitle}
                </Typography>
              </Box>
              <Box m={4} textAlign="center">
                <Typography>
                  {parse(i18n.RevolvingLine.Offer.customerInDebtBody)}
                </Typography>
              </Box>
              <Box m={4} textAlign="center">
                <Button
                  variant="text"
                  className={classes.link}
                  onClick={() => setShowHelp(true)}
                >
                  {i18n.RevolvingLine.Offer.helpTitle}
                </Button>
              </Box>

              <Box m={8} />

              <Box m={4}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={goToPay}
                  fullWidth
                >
                  {i18n.RevolvingLine.Offer.payInstallmentButton}
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Box m={2}>
                <img src={imgTarjetaLineaCredito} width="90px" alt="img linea de crédito" />
              </Box>
              <Box m={2}>
                <Typography variant="h2">
                  {parse(fillMessageWith(
                    i18n.RevolvingLine.Offer.title,
                    moneyFormatter(customerRevolvingLine?.campaignOffer?.maxAmountOffer),
                  ))}
                </Typography>
              </Box>

              <Box m={4} textAlign="left">
                <Typography>
                  {parse(i18n.RevolvingLine.Offer.titleList)}
                </Typography>
              </Box>

              <Box m={1} textAlign="left">
                <ul>
                  <li className={classes.listBullet}>
                    <Typography className={classes.listText}>
                      {parse(i18n.RevolvingLine.Offer.listItemOne)}
                    </Typography>
                  </li>
                  <Box m={2} />
                  <li className={classes.listBullet}>
                    <Typography className={classes.listText}>
                      {parse(i18n.RevolvingLine.Offer.listItemTwo)}
                      <Button
                        variant="text"
                        className={classes.link}
                        onClick={() => setShowHelp(true)}
                      >
                        {i18n.RevolvingLine.Offer.link}
                      </Button>
                    </Typography>
                  </li>
                </ul>
              </Box>

              <Box m={4}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={goToNewLoan}
                  fullWidth
                >
                  {i18n.RevolvingLine.Offer.button}
                </Button>
              </Box>
            </>
          )}
        </>
      )}

    </>
  );
};

export default RevolvingLineOffer;
