import {
  Box, Button, Grid, Typography,
} from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert } from '@material-ui/lab';
import useStyle from './loginStyle';
import Loading from '../../components/common/Loading';
import Header from '../../components/header/Header';
import i18n from '../../common/i18n';
import { useLoginData } from '../../contexts/LoginDataContext';
import { capitalizeSentence, fillMessageWith } from '../../utils/functionsUtil';
import { loginPasswordSchemaValidation } from '../../forms/schemaValidations/schemaValidations';
import LoadingButton from '../../components/common/LoadingButton';
import MessageResponse from '../../components/common/MessageResponse';
import { AUTHENTICATE_UPDATE_CREDENCIALS_SUCCESS, LOGIN_VERIFICATION_CODE } from '../../utils/referenceConstant';
import PasswordInput from '../../components/common/PasswordInput';
import useLoginPasswordController from '../../customHook/controller/useLoginPasswordController';

const LoginPassword = () => {
  const classes = useStyle();
  const {
    initLoading, loading, changeUser, doLogin, continueWithSms, invalidCredentials, onPasswordResetContinue,
    errorResponse, retry, wasPasswordChanged, hasWebAuthnCredentials,
  } = useLoginPasswordController();
  const { currentLoginData } = useLoginData();
  const methods = useForm({
    resolver: yupResolver(loginPasswordSchemaValidation),
  });

  // Para cuando llega a esta pantalla por reseteo de contraseña
  if (wasPasswordChanged) {
    return (
      <>
        <Header />
        <Box mt={15} />
        <MessageResponse
          response={{ status: 200 }}
          referenceLabels={AUTHENTICATE_UPDATE_CREDENCIALS_SUCCESS}
          onSuccessPrimary={onPasswordResetContinue}
          successGreen
        />
      </>
    );
  }

  return (
    <>
      <Header />
      {errorResponse ? (
        <>
          <Box mt={15} />
          <MessageResponse
            response={errorResponse}
            referenceLabels={LOGIN_VERIFICATION_CODE}
            onErrorPrimary={retry}
            canGoHome={false}
          />
        </>
      ) : (
        <Grid container className={classes.container}>
          <Grid item xs={1} md={1} />
          <Grid item xs={10} md={5} style={{ marginTop: '120px' }}>
            {initLoading ? (
              <Loading />
            ) : (
              <>
                <Typography
                  variant="h1"
                  align="left"
                  style={{ color: '#212121', marginBottom: '16px', fontFamily: 'Open sans' }}
                >
                  {i18n.LoginPassword.title}
                </Typography>
                <Typography align="left">
                  {i18n.LoginPassword.userLabel}
                </Typography>
                <Box
                  style={{
                    marginTop: '8px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: '#f5f5f5',
                    padding: '10px 20px',
                  }}
                >
                  <Typography align="left">
                    <b>{capitalizeSentence(currentLoginData.fullname)}</b>
                    <Typography>
                      {fillMessageWith(i18n.loginIdentityResolverCUIT, currentLoginData.customerTaxId)}
                    </Typography>
                  </Typography>
                  <Button onClick={changeUser}>
                    <b>{i18n.LoginPassword.changeUser}</b>
                  </Button>
                </Box>
                <Typography align="left" style={{ marginTop: '18px' }}>
                  {i18n.LoginPassword.inputPass}
                </Typography>
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(doLogin)}>
                    <PasswordInput
                      name="pass"
                      label={i18n.LoginPassword.pass}
                      fullWidth
                      mandatory
                      style={{ marginTop: '20px' }}
                    />
                    {invalidCredentials && (
                      <Alert severity="error" style={{ marginTop: '20px', marginBottom: '12px' }}>
                        {i18n.LoginPassword.invalidUserOrPass}
                      </Alert>
                    )}
                    <Button
                      onClick={continueWithSms}
                      style={{ color: '#a0a0a0', marginTop: '8px', marginBottom: '32px' }}
                    >
                      {i18n.LoginPassword.forgetMyPass}
                    </Button>
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      type="submit"
                      loading={loading}
                      style={{
                        width: '100%',
                      }}
                    >
                      {i18n.LoginPassword.cta}

                    </LoadingButton>
                    {hasWebAuthnCredentials && (
                      <LoadingButton
                        variant="contained"
                        color="primary"
                        loading={loading}
                        style={{
                          width: '100%',
                        }}
                      >
                        Iniciar sesión con método de desbloqueo
                      </LoadingButton>
                    )}
                  </form>
                </FormProvider>
              </>
            )}
          </Grid>
          <Grid item xs={1} md={5} />
        </Grid>
      )}
    </>
  );
};

export default LoginPassword;
