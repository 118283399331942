/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Typography,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ExitToApp from '@material-ui/icons/ExitToApp';
import { useMediaQuery } from '@react-hook/media-query';
import ReactGA from 'react-ga4';
import { useHistory } from 'react-router';
import imgMiTarjeta from '../assets/imgs/mi-tarjeta.svg';
import imgMisCuotas from '../assets/imgs/mis-cuotas.svg';
import imgMyPayments from '../assets/imgs/mis-pagos.svg';
import imgNuevoPrestamo from '../assets/imgs/nuevo-prestamo.svg';
import imgRevolvingLineOffer from '../assets/imgs/revolving-line-offer.png';
import imgPagarCuota from '../assets/imgs/pagar-cuota.svg';
import imgPagarServicio from '../assets/imgs/pagar-servicio.svg';
import imgPrepaidCardActivateBannerDesktop from '../assets/imgs/prepaid-card-activate-banner-desktop.svg';
import imgPrepaidCardActivateBanner from '../assets/imgs/prepaid-card-activate-banner.svg';
import imgRecargarCelular from '../assets/imgs/recargar-celular.svg';
import i18n from '../common/i18n';
import CardCustomerDebtor from '../components/CardCustomerDebtor';
import CardCustomerLegalStudy from '../components/CardCustomerLegalStudy';
import CardHome from '../components/CardHome';
import ClickeableBanner from '../components/ClickeableBanner';
import NotificationDialogHandler from '../components/NotificationDialogHandler';
import PrepaidBenefitDialog from '../components/PrepaidBenefitDialog';
import Header from '../components/common/Header';
import BannerSlider from '../components/BannerSlider';
import { useAuth } from '../contexts/AuthContext';
import { useCustomerAccountStatus } from '../contexts/CustomerAccountStatusContext';
import { fillMessageWith, firstLetterToUpperCase } from '../utils/functionsUtil';
import { getPathRouteFromKey } from '../utils/menuAndRoutesUtil';
import usePushNotificationSubscription from '../customHook/usePushNotificationSubscription';
import Loading from '../components/common/Loading';
import { hasPermissionGrantedOrDenied } from '../utils/firebase';

const Home = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const history = useHistory();
  const { currentUser } = useAuth();
  const {
    currentUser: {
      customerFirstName = '',
      lastPendingLoan = null,
    } = {},
  } = useAuth() || {};
  const firstName = customerFirstName.split(' ')[0];
  const {
    customerAccountStatus: {
      customerIsUpToDate = true,
      customerIsInBlacklist = false,
      customerHasPrepaidCard = false,
      customerHasPrepaidCardToActivate = false,
      totalInstallmentDebt = 0,
      totalPunitiveDebt = 0,
      totalDebt = 0,
      managmentLegalStudyStatus = null,
      legalStudyContact = null,
      customerRevolvingLine = null,
    } = {},
  } = useCustomerAccountStatus() || {};

  const [loading, setLoading] = useState(true);

  const handleOnClickLogout = () => {
    ReactGA.set({ userId: undefined });
    history.push(getPathRouteFromKey(i18n.logoutKey));
  };

  const handleGoToPay = () => {
    history.push(getPathRouteFromKey(i18n.payInstallmentKey));
  };

  const goToActivateCard = () => {
    history.push(getPathRouteFromKey(i18n.myCardActivateKey));
  };

  const init = () => {
    if (!currentUser.hasCredentials) {
      history.push(getPathRouteFromKey(i18n.generatePassword));
    } else if (!hasPermissionGrantedOrDenied() && !currentUser.skipNotification) {
      history.push(getPathRouteFromKey(i18n.pushNotificationSubscription));
    } else {
      setLoading(false);
    }
  };

  useEffect(init, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Header title={fillMessageWith(i18n.homeHeaderWelcome, [firstLetterToUpperCase(firstName)])} />

      { managmentLegalStudyStatus !== null ? (
        <CardCustomerLegalStudy
          managmentLegalStudyStatus={managmentLegalStudyStatus}
          legalStudyContact={legalStudyContact}
        />
      ) : (
        <>
          <Container
            maxWidth="lg"
          >

            { customerIsUpToDate === false && (
            <>
              <Box m={3} />
              <CardCustomerDebtor
                totalInstallmentDebt={totalInstallmentDebt}
                totalPunitiveDebt={totalPunitiveDebt}
                totalDebt={totalDebt}
                onClickPay={handleGoToPay}
              />
            </>
            )}

            <Box m={3} />
            <Grid
              container
              justify="center"
            >
              <Grid
                item
                style={{
                  maxWidth: isMobile ? '327px' : '449px',
                  minWidth: isMobile ? '327px' : '449px',
                }}
              >
                <BannerSlider />

                { customerHasPrepaidCardToActivate === true && (
                  <Box mt={3} mb={3}>
                    <ClickeableBanner
                      onClick={goToActivateCard}
                      imageUrl={isMobile ? imgPrepaidCardActivateBanner : imgPrepaidCardActivateBannerDesktop}
                    />
                  </Box>
                )}
                <Grid
                  container
                  justify="left"
                >
                  <Typography variant="h4">
                    {i18n.homeSelfManagmentTitle}
                  </Typography>
                </Grid>
                <Grid
                  container
                  justify="space-between"
                  style={{
                    paddingTop: '20px', paddingBottom: '20px',
                  }}
                >
                  <Grid item>
                    <CardHome
                      title={i18n.homeLoanInstallments}
                      urlImage={imgMisCuotas}
                      pathOnClick={getPathRouteFromKey(i18n.myLoansKey)}
                      key={i18n.homeLoanInstallments}
                    />
                  </Grid>
                  <Grid item>
                    <CardHome
                      title={i18n.homePayInstallment}
                      urlImage={imgPagarCuota}
                      pathOnClick={getPathRouteFromKey(i18n.payInstallmentKey)}
                      key={i18n.homePayInstallment}
                    />
                  </Grid>
                  <Grid item>
                    {customerHasPrepaidCard ? (
                      <CardHome
                        title={i18n.homeMyCard}
                        urlImage={imgMiTarjeta}
                        pathOnClick={getPathRouteFromKey(i18n.myCardKey)}
                        key={i18n.homeMyCard}
                      />

                    ) : (
                      <CardHome
                        title={i18n.homeMyPayments}
                        urlImage={imgMyPayments}
                        pathOnClick={getPathRouteFromKey(i18n.myPaymentsMenuKey)}
                        key={i18n.homeMyPayments}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>

          <Container
            maxWidth="lg"
            style={{
              paddingTop: '20px',
              backgroundColor: customerIsUpToDate && !customerIsInBlacklist ? '#F5F5F5' : 'white',
            }}
          >
            <Grid
              container
              justify="center"
            >
              { customerIsUpToDate && !customerIsInBlacklist && (
              <>
                <Grid container justify="center">
                  <Grid
                    item
                    style={{
                      maxWidth: isMobile ? '327px' : '449px',
                      minWidth: isMobile ? '327px' : '449px',
                    }}
                  >
                    <Grid
                      container
                      justify="left"
                    >
                      <Typography variant="h4">
                        {i18n.homeNewLoanTitle}
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      justify="space-between"
                      style={{
                        paddingTop: '20px', paddingBottom: '20px',
                      }}
                    >
                      <Grid item>
                        <CardHome
                          title={i18n.homeNewLoan}
                          urlImage={imgNuevoPrestamo}
                          pathOnClick={getPathRouteFromKey(i18n.newLoanKey)}
                          key={i18n.homeNewLoan}
                        />
                      </Grid>

                      { customerRevolvingLine?.maxAmount || customerRevolvingLine?.campaignOffer ? (
                        <>
                          <Grid item>
                            <CardHome
                              title={i18n.homeServicesAndRecharge}
                              urlImage={imgPagarServicio}
                              pathOnClick={getPathRouteFromKey(i18n.moreProductsKey)}
                              key={i18n.homeServicesAndRecharge}
                            />
                          </Grid>
                          <Grid item>
                            <CardHome
                              title={i18n.homeRevolvingLineOffer}
                              urlImage={imgRevolvingLineOffer}
                              pathOnClick={getPathRouteFromKey(i18n.revolvingLineOffer)}
                              key={i18n.homeRevolvingLineOffer}
                            />
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item>
                            <CardHome
                              title={i18n.homePayService}
                              urlImage={imgPagarServicio}
                              pathOnClick={`${getPathRouteFromKey(i18n.newLoanKey)}?product=19&subproduct=91`}
                              key={i18n.homePayService}
                            />
                          </Grid>
                          <Grid item>
                            <CardHome
                              title={i18n.homeRecharge}
                              urlImage={imgRecargarCelular}
                              pathOnClick={`${getPathRouteFromKey(i18n.newLoanKey)}?product=19&subproduct=69`}
                              key={i18n.homeRecharge}
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </>
              )}
              <Grid container justify="center">
                <Grid item lg={2} sm={4} xs={11}>
                  <Box m={2} />
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleOnClickLogout}
                    startIcon={<ExitToApp />}
                  >
                    {i18n.logoutMenuLabel}
                  </Button>
                  <Box m={6} />
                </Grid>
              </Grid>

            </Grid>

          </Container>
        </>
      )}

      <NotificationDialogHandler />
      <PrepaidBenefitDialog />
    </>
  );
};

export default Home;
