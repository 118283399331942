import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import {
  Grid, Typography, makeStyles, Button,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import i18n from '../../common/i18n';
import { getPathRouteFromKey } from '../../utils/menuAndRoutesUtil';
import { fillMessageWith, isEnableToPrepaid } from '../../utils/functionsUtil';
import {
  moneyFormatter, moneyFormatterWithoutDecimals, dateFormatter,
} from '../../utils/formatterUtil';
import RevolvingLineDetailAccordion from './RevolvingLineDetailAccordion';

const useStyles = makeStyles(() => ({
  loanAmount: {
    textAlign: 'left',
    fontWeight: '700',
    fontSize: '16px',
    fontFamily: 'Poppins',
    color: '#212121',
  },
  loanDueDate: {
    textAlign: 'left',
    fontSize: '14px',
    fontWeight: '400',
    fontFamily: 'Open Sans',
    color: '#424242',
  },
  loanDetailTitle: {
    fontSize: '14px',
    color: '#757575',
  },
  loanDetailInfo: {
    fontWeight: '400',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    color: '#424242',
    lineHeight: '24px',
  },
  loanDetailCTA: {
    fontWeight: '700',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    color: '#424242',
    textDecoration: 'underline',
    lineHeight: '24px',
    padding: '0px',
  },
}));

const RevolvingLineDetailLoansInstallments = ({ loanInstallments }) => {
  const classes = useStyles();
  const history = useHistory();

  const goToPrepaidLoan = (idLoan) => {
    history.push(`${getPathRouteFromKey(i18n.prePaidLoanKey)}?idLoan=${idLoan}`);
  };

  const goToDismiss = () => {
    history.push(getPathRouteFromKey(i18n.revolvingLineDismiss));
  };

  return (
    <>
      <Grid container style={{ marginTop: '5px' }}>
        {loanInstallments.map((inst) => (
          <Grid item xs={12} style={{ marginTop: '10px' }}>
            <Typography className={classes.loanAmount}>
              {parse(fillMessageWith(i18n.RevolvingLine.Detail.loanAmount,
                moneyFormatterWithoutDecimals(inst.totalDisburse)))}
            </Typography>
            <Typography className={classes.loanDueDate} style={{ marginTop: '3px', marginBottom: '5px' }}>
              {fillMessageWith(i18n.RevolvingLine.Detail.loanDueDate,
                dateFormatter(inst.createdDate))}
            </Typography>
            <RevolvingLineDetailAccordion
              title={(
                <span className={classes.loanDetailTitle}>
                  {i18n.RevolvingLine.Detail.loanDetail}
                </span>
              )}
            >
              <Typography className={classes.loanDetailInfo}>
                {fillMessageWith(i18n.RevolvingLine.Detail.loanInstallments,
                  inst.installmentNumber, inst.installments, moneyFormatter(inst.installmentAmount))}
              </Typography>

              {isEnableToPrepaid(inst.createdDate) ? (
                <Button
                  variant="text"
                  className={classes.loanDetailCTA}
                  onClick={() => goToPrepaidLoan(inst.idLoan)}
                >
                  {i18n.RevolvingLine.Detail.cancelLoan}
                </Button>
              ) : (
                <Button
                  variant="text"
                  className={classes.loanDetailCTA}
                  onClick={() => goToDismiss()}
                >
                  {i18n.RevolvingLine.Detail.dismissLoan}
                </Button>
              )}

            </RevolvingLineDetailAccordion>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

RevolvingLineDetailLoansInstallments.propTypes = {
  loanInstallments: PropTypes.arrayOf(PropTypes.shape({
    idLoan: PropTypes.number,
    totalDisburse: PropTypes.number,
    createdDate: PropTypes.string,
    installmentNumber: PropTypes.number,
    installments: PropTypes.number,
    installmentAmount: PropTypes.number,
  })).isRequired,
};

export default RevolvingLineDetailLoansInstallments;
