import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { getEnv, URL_NEW_LOAN } from '../api/env';
import i18n from '../common/i18n';
import CardOfferBanner from './CardOfferBanner';
import { useCustomerAccountStatus } from '../contexts/CustomerAccountStatusContext';
import { useAuth } from '../contexts/AuthContext';
import { getPathRouteFromKey } from '../utils/menuAndRoutesUtil';

const CardOfferWrapper = ({
  useSmallBackgroundImage,
}) => {
  const history = useHistory();

  const {
    currentUser: {
      lastPendingLoan = null,
    } = {},
  } = useAuth() || {};
  const {
    customerAccountStatus: {
      customerIsUpToDate = true,
      renewersCampaignOffer = null,
      loanOffer = null,
    } = {},
  } = useCustomerAccountStatus() || {};

  const redirectToLastLoanOffer = () => {
    if (lastPendingLoan.hash) {
      window.open(getEnv(URL_NEW_LOAN) + lastPendingLoan.hash, '_SELF');
    }
  };

  const goToCampaignOffer = () => {
    history.push(getPathRouteFromKey(i18n.newLoanKey));
  };

  const redirectToNewLoanOffer = () => {
    if (loanOffer.hashKey) {
      window.open(getEnv(URL_NEW_LOAN) + loanOffer.hashKey, '_SELF');
    }
  };

  return (
    <>
      {customerIsUpToDate && (
        <>
          {lastPendingLoan && lastPendingLoan.hash && (lastPendingLoan.maxAmount || lastPendingLoan.requestedAmount)
            && (
              <CardOfferBanner
                onClick={redirectToLastLoanOffer}
                textOffer={lastPendingLoan.installmentAmountSelected && lastPendingLoan.installmentsNumberSelected
                  ? i18n.pendingLoanText : i18n.newLoanText}
                maxAmountOffer={lastPendingLoan.maxAmount || lastPendingLoan.requestedAmount}
                useSmallBackgroundImage={useSmallBackgroundImage}
              />
            )}
          {!lastPendingLoan && renewersCampaignOffer && renewersCampaignOffer.maxAmountOffer && (
            <CardOfferBanner
              onClick={goToCampaignOffer}
              textOffer={i18n.campaignLoanText}
              maxAmountOffer={renewersCampaignOffer.maxAmountOffer}
              useSmallBackgroundImage={useSmallBackgroundImage}
            />
          )}
          {!lastPendingLoan && !renewersCampaignOffer && loanOffer && loanOffer.hashKey && loanOffer.maxAmount && (
            <CardOfferBanner
              onClick={redirectToNewLoanOffer}
              textOffer={i18n.newLoanText}
              maxAmountOffer={loanOffer.maxAmount}
              useSmallBackgroundImage={useSmallBackgroundImage}
            />
          )}
        </>
      )}
    </>
  );
};

CardOfferWrapper.propTypes = {
  useSmallBackgroundImage: PropTypes.bool,
};

CardOfferWrapper.defaultProps = {
  useSmallBackgroundImage: false,
};

export default CardOfferWrapper;
