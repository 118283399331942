import { yupResolver } from '@hookform/resolvers/yup';
import {
  Checkbox,
  Grid,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import i18n from '../common/i18n';
import { loginDNIValidationSchema } from '../forms/schemaValidations/schemaValidations';
import TextInput from './common/TextInput';
import LoadingButton from './common/LoadingButton';
import { getRememberLoginData } from '../api/userStorage';
import useStyle from '../pages/login/loginStyle';

const LoginDNIValidationStep = ({ onSubmit, loading }) => {
  const classes = useStyle();
  const methods = useForm({
    resolver: yupResolver(loginDNIValidationSchema),
  });

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <TextInput
            name="dni"
            label={i18n.loginDNIValidationStepInputDniLabel}
            fullWidth
            helperText={i18n.loginDNIValidationStepHelperText}
            inputProps={{ minLength: 6, maxLength: 8 }}
            mandatory
          />
          <Typography align="left">
            <Controller
              name="remember"
              control={methods.control}
              defaultValue={getRememberLoginData()?.remember || true}
              render={({ field }) => (
                <Checkbox {...field} color="primary" checked={field.value} />
              )}
            />
            {i18n.loginDNIValidationStepInputRememberLabel}
          </Typography>
          <Grid container className={classes.ctaContainer}>
            <Grid item xs={12}>
              <LoadingButton
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                loading={loading}
                style={{
                  width: '100%',
                }}
              >
                {i18n.loginDNIValidationStepCTA}

              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};

LoginDNIValidationStep.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default LoginDNIValidationStep;
