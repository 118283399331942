import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useMediaQuery } from '@react-hook/media-query';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import { useState } from 'react';
import {
  Link,
} from 'react-router-dom';
import logo from '../assets/imgs/logo.svg';
import CustomerStatus from '../components/CustomerStatus';
import MenuDrawer from '../components/MenuDrawer';
import ProtectedRoutes from './ProtectedRoutes';
import { getPathRouteFromKey } from '../utils/menuAndRoutesUtil';
import i18n from '../common/i18n';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    '@media (max-width:600px)': {
      marginRight: 0,
    },
  },
  hide: {
    display: 'none',
  },

  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifycontent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(0), padding del
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },

  customerStatus: {
    marginRight: '15px',
    '@media (max-width:600px)': {
      marginRight: '10px',
    },
  },
}));

const MainContent = () => {
  const matchesDesktop = useMediaQuery('only screen and (min-width: 760px)');
  const classes = useStyles();
  const [open, setOpen] = useState(matchesDesktop);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const closeDrawer = () => {
    setOpen(false);
  };
  const itemClick = () => {
    if (!matchesDesktop) {
      setOpen(false);
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar color="default" position="fixed" className={classes.appBar}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={open ? closeDrawer : handleDrawerOpen}
                edge="start"
                className={classes.menuButton}
              >
                {open ? <ChevronLeftIcon /> : <MenuIcon />}
              </IconButton>
              <Link to={getPathRouteFromKey(i18n.homeKey)}>
                <img src={logo} className="logo-cc" alt="Logo" />
              </Link>
            </Toolbar>
          </div>
          <div>
            <div className={classes.customerStatus}>
              <CustomerStatus style={{ display: 'flex' }} />
            </div>
          </div>
        </div>
      </AppBar>
      <MenuDrawer
        openMenu={open}
        onHandleDrawerClose={closeDrawer}
        onHandleItemClick={itemClick}
      />
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        <ProtectedRoutes />
      </main>
    </div>
  );
};
export default MainContent;
