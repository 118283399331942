import {
  Box, Grid, makeStyles, Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@react-hook/media-query';
import { moneyFormatter } from '../utils/formatterUtil';
import cardOfferBannerBackground from '../assets/imgs/card-offer-banner-background.svg';
import cardOfferBannerBackgroundDesktop from '../assets/imgs/card-offer-banner-background-desktop.svg';
import cardOfferBannerBackgroundSmall from '../assets/imgs/card-offer-banner-background-small.svg';
import cardOfferBannerIcon from '../assets/imgs/card-offer-banner-icon.png';

const useStyles = makeStyles(() => ({
  imageWrapper: {
    borderRadius: '12px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    cursor: 'pointer',
  },
  iconWrapper: {
    width: 'auto',
    backgroundImage: `url(${cardOfferBannerIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
  },
  textWrapper: {
    textAlign: 'left',
  },
  text: {
    fontFamily: 'Poppins',
    fontWeight: '700',
    lineHeight: '1.5',
  },
}));

const CardOfferBanner = ({
  onClick, textOffer, maxAmountOffer, useSmallBackgroundImage,
}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:600px)');

  const styleImageWrapper = {
    backgroundImage: useSmallBackgroundImage ? `url(${cardOfferBannerBackgroundSmall})`
      : isMobile ? `url(${cardOfferBannerBackground})`
        : `url(${cardOfferBannerBackgroundDesktop})`,
    height: useSmallBackgroundImage ? '70px' : '134px',
    maxWidth: useSmallBackgroundImage || isMobile ? '327px' : '993px',
  };

  const styleIconWrapper = {
    marginTop: useSmallBackgroundImage || !isMobile ? '2%' : '5%',
    maxHeight: useSmallBackgroundImage ? '58px' : isMobile ? '90px' : '100px',
  };

  const styleTextWrapper = {
    paddingTop: useSmallBackgroundImage ? '5%' : '20px',
  };

  const styleText = {
    fontSize: useSmallBackgroundImage ? '0.7rem' : isMobile ? '0.9rem' : '1.2rem',
  };

  return (
    <>
      <Grid
        container
        onClick={onClick}
        className={classes.imageWrapper}
        style={styleImageWrapper}
      >
        <Grid item xs={6} md={5} className={classes.iconWrapper} style={styleIconWrapper}>
          <Box m={1} />
        </Grid>
        <Grid item xs={6} md={7}>
          <Grid className={classes.textWrapper} style={styleTextWrapper}>
            <Typography className={classes.text} style={{ ...styleText, color: '#E72064' }}>
              {textOffer[0]}
            </Typography>
            {isMobile ? (
              <>
                <Typography className={classes.text} style={styleText}>
                  {textOffer[1]}
                </Typography>
                <Typography className={classes.text} style={styleText}>
                  {textOffer[2]}
                  {' '}
                  <span style={{ color: '#E72064' }}>
                    {moneyFormatter(maxAmountOffer)}
                  </span>
                </Typography>
              </>
            ) : (
              <>
                <Typography className={classes.text} style={styleText}>
                  {textOffer[1]}
                  {' '}
                  {textOffer[2]}
                  {' '}
                  <span style={{ color: '#E72064' }}>
                    {moneyFormatter(maxAmountOffer)}
                  </span>
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

CardOfferBanner.propTypes = {
  onClick: PropTypes.func.isRequired,
  textOffer: PropTypes.arrayOf(PropTypes.string).isRequired,
  maxAmountOffer: PropTypes.number.isRequired,
  useSmallBackgroundImage: PropTypes.bool,
};

CardOfferBanner.defaultProps = {
  useSmallBackgroundImage: false,
};

export default CardOfferBanner;
