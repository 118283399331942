import { useState, createContext, useContext } from 'react';

import PropTypes from 'prop-types';

const CustomerAccountStatusContext = createContext(undefined);
// wrapper for the provider
export const CustomerStatusProvider = ({ customerStatus, children }) => {
  const [customerAccountStatus, setCustomerAccountStatus] = useState(customerStatus);
  return (
    <CustomerAccountStatusContext.Provider value={{ customerAccountStatus, setCustomerAccountStatus }}>
      {children}
    </CustomerAccountStatusContext.Provider>
  );
};
CustomerStatusProvider.propTypes = {
  customerStatus: PropTypes.shape({}),
  children: PropTypes.node.isRequired,

};
CustomerStatusProvider.defaultProps = {
  customerStatus: null,
};
export const useCustomerAccountStatus = () => useContext(CustomerAccountStatusContext);
