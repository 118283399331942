import { Button, Grid } from '@material-ui/core';
import useWebAuthnRegisterController from '../customHook/controller/useWebAuthnRegisterController';

const WebAuthnRegister = () => {
  const { register } = useWebAuthnRegisterController();

  return (
    <Grid container justify="center">
      <Grid item xs={10}>
        <Button
          style={{ marginTop: '50px' }}
          variant="contained"
          color="primary"
          onClick={register}
        >
          Registrar método de desbloqueo
        </Button>
      </Grid>
    </Grid>
  );
};

export default WebAuthnRegister;
