import { Box, Grid } from '@material-ui/core';
import i18n from '../../../common/i18n';
import CardOfferBanner from '../../../components/CardOfferBanner';

const WikiCardOffer = () => {
  const maxAmountOffer = 25000;
  const redirectLink = () => {
    window.open('http://www.google.com.ar', '_SELF');
  };

  return (
    <>
      <Box m={3} />
      <Grid container justify="center">
        <CardOfferBanner
          onClick={redirectLink}
          textOffer={i18n.pendingLoanText}
          maxAmountOffer={maxAmountOffer}
        />
      </Grid>
      <Box m={3} />
      <Grid container justify="center">
        <CardOfferBanner
          onClick={redirectLink}
          textOffer={i18n.campaignLoanText}
          maxAmountOffer={maxAmountOffer}
        />
      </Grid>
    </>
  );
};

export default WikiCardOffer;
