import fetch from './api';
import { API_SELFIE, getEnv } from './env';

const urlBackend = getEnv(API_SELFIE);

export const getRegistrationChallenge = () =>
  fetch({
    baseURL: `${urlBackend}`,
    url: '/webauthn/registration-challenge',
    method: 'get',
  });

export const registerCredentials = (credentials) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: '/webauthn/register',
    method: 'post',
    data: credentials,
  });

export const hasCredentials = (customerTaxId) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/webauthn/registration/${customerTaxId}`,
    method: 'get',
  });
