/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable import/no-extraneous-dependencies */
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { subscribe } from '../api/pushNotificationApiService';

export const isSupported = () =>
  'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window;

let messaging;
if (isSupported()) {
  const firebaseConfig = {
    apiKey: 'AIzaSyB3YBhuabIoEADLVgD3hJbXZ88P7mbYKo0',
    authDomain: 'originacion-207119.firebaseapp.com',
    databaseURL: 'https://originacion-207119.firebaseio.com',
    projectId: 'originacion-207119',
    storageBucket: 'originacion-207119.appspot.com',
    messagingSenderId: '940683506318',
    appId: '1:940683506318:web:4a26e81336e8f4c2844fdf',
  };

  const app = initializeApp(firebaseConfig);
  messaging = getMessaging(app);
}

export const hasPermissionGrantedOrDenied = () => {
  if (isSupported()) {
    // Si soporta notificaciones chequea el permiso real
    return Notification.permission === 'granted' || Notification.permission === 'denied';
  }
  // caso contrario asume como que ya fue concedido o denegado y no muestra el popup
  return true;
};

export const requestPermission = async (customerID, isRetry) => {
  if (isSupported()) {
    const vapidKey = 'BCanDYE8HK3yDAhwhFW5-lIkLOfL1k17OdaWZApRfIdTiped8DKH9Z1kjk5gwmvFO0-CasAUWSLIMGD7qAJ3gPc';
    try {
      const currentToken = await getToken(messaging, { vapidKey });
      if (currentToken) {
        const subscriptionData = {
          externalCustomerID: customerID,
          firebaseToken: currentToken,
        };
        subscribe(subscriptionData);
      } else {
        console.log('Failed to generate the app registration token.');
      }
    } catch (err) {
      console.log('An error occurred when requesting to receive the token.', err);
      if (!isRetry) {
        await requestPermission(customerID, true);
      }
    }
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    if (isSupported()) {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
    }
  });
