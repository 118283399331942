import { Drawer } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useMediaQuery } from '@react-hook/media-query';
import PropTypes from 'prop-types';
import AvatarMenu from './AvatarMenu';

import {
  MenuItemsSection1, MenuItemsSection2, MenuItemsSection3, MenuItemsSection4, MenuItemsSection5,
} from '../utils/menuAndRoutesUtil';
import MenuDrawerSectionListItem from './MenuSectionListItem';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifycontent: 'flex-end',
  },

}));
const MenuDrawer = ({ openMenu, onHandleDrawerClose, onHandleItemClick }) => {
  const matchesDesktop = useMediaQuery('only screen and (min-width: 760px)');
  const classes = useStyles();
  const theme = useTheme();

  const handleDrawerClose = () => {
    onHandleDrawerClose(false);
  };
  const handleItemClick = () => {
    onHandleItemClick(false);
  };

  return (

    <>
      <Drawer
        className={classes.drawer}
        variant={matchesDesktop ? 'persistent' : 'temporary'}
        anchor="left"
        open={openMenu}
        onClose={handleDrawerClose}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >

        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <AvatarMenu />
        <Divider />
        <List>
          <MenuDrawerSectionListItem menuItems={MenuItemsSection1} onHandleItemClick={handleItemClick} />
          <Divider />
          <MenuDrawerSectionListItem menuItems={MenuItemsSection2} onHandleItemClick={handleItemClick} />
          <Divider />
          <MenuDrawerSectionListItem menuItems={MenuItemsSection3} onHandleItemClick={handleItemClick} />
          <Divider />
          <MenuDrawerSectionListItem menuItems={MenuItemsSection4} onHandleItemClick={handleItemClick} />
          <Divider />
          <MenuDrawerSectionListItem menuItems={MenuItemsSection5} onHandleItemClick={handleItemClick} />
        </List>
      </Drawer>
    </>
  );
};
MenuDrawer.propTypes = {
  openMenu: PropTypes.bool.isRequired,
  onHandleDrawerClose: PropTypes.func.isRequired,
  onHandleItemClick: PropTypes.func.isRequired,
};
export default MenuDrawer;
