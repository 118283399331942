import Box from '@material-ui/core/Box';
import {
  Grid, Typography, makeStyles, Button, ListItem, ListItemIcon, ListItemText,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import i18n from '../common/i18n';
import RevolvingLineDetailLoansInstallments from '../components/common/RevolvingLineDetailLoansInstallments';
import { useCustomerAccountStatus } from '../contexts/CustomerAccountStatusContext';
import { getPathRouteFromKey } from '../utils/menuAndRoutesUtil';
import imgBack from '../assets/imgs/atras.svg';

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    padding: '25px',
  },
  headerTitle: {
    fontWeight: '700',
    fontSize: '24px',
    fontFamily: 'Poppins',
    color: 'white',
  },
  loansTitle: {
    textAlign: 'left',
    fontFamily: 'Poppins',
    fontWeight: '700',
    fontSize: '22px',
  },
  loanAmount: {
    textAlign: 'left',
    fontWeight: '700',
    fontSize: '16px',
    fontFamily: 'Poppins',
    color: '#212121',
  },
  loanDueDate: {
    textAlign: 'left',
    fontSize: '14px',
    fontWeight: '400',
    fontFamily: 'Open Sans',
    color: '#424242',
  },
  loanDetailTitle: {
    fontSize: '14px',
    color: '#757575',
  },
  loanDetailInfo: {
    fontWeight: '400',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    color: '#424242',
  },
  arrowBack: {
    fontFamily: 'Open Sans',
    fontWeight: '700',
    fontSize: '14px',
  },
}));

const RevolvingLineDetailLoans = () => {
  const classes = useStyles();
  const history = useHistory();

  const {
    customerAccountStatus: {
      customerRevolvingLine = null,
    } = {},
  } = useCustomerAccountStatus() || {};

  const goToRevolvingLineDetail = () => {
    history.push(getPathRouteFromKey(i18n.revolvingLineDetail));
  };

  return (
    <>
      <Box component="header" className={classes.header}>
        <Typography className={classes.headerTitle}>
          {i18n.RevolvingLine.Header.title}
        </Typography>
      </Box>
      <Grid container justify="center" style={{ marginTop: '20px' }}>
        <Grid item style={{ maxWidth: '540px', paddingLeft: '5%', paddingRight: '5%' }}>
          <Grid container justify="center">
            <Grid item xs={12} style={{ marginTop: '10px' }}>
              <Typography className={classes.loansTitle}>
                {i18n.RevolvingLine.Detail.loansTitle}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginTop: '10px' }}>
              <RevolvingLineDetailLoansInstallments
                loanInstallments={customerRevolvingLine.recharges}
              />
            </Grid>
            <Grid item xs={12} sm={8} style={{ marginTop: '10px' }}>
              <Button
                variant="tertiary"
                onClick={goToRevolvingLineDetail}
              >
                <ListItem style={{ padding: '0px' }}>
                  <ListItemIcon style={{ minWidth: '24px' }}>
                    <img src={imgBack} alt="back" width="20px" />
                  </ListItemIcon>
                  <ListItemText
                    primary={(
                      <Typography className={classes.arrowBack}>
                        {i18n.RevolvingLine.DetailInstallments.arrowBack}
                      </Typography>
                    )}
                  />
                </ListItem>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default RevolvingLineDetailLoans;
