import { useState } from 'react';
import { Base64 } from 'js-base64';
import { getRegistrationChallenge, registerCredentials } from '../../api/webAuthnApiService';
import { getEnv, REACT_APP_LENDING_SELFIE_ENDPOINT } from '../../api/env';
import { useAuth } from '../../contexts/AuthContext';
import { capitalizeSentence } from '../../utils/functionsUtil';
import { useToaster } from '../../contexts/ToasterContext';

const useWebAuthnRegisterController = () => {
  const { currentUser } = useAuth();
  const { showToast } = useToaster();
  const [loading, setLoading] = useState();

  const register = async () => {
    try {
      setLoading(true);
      const { data: challenge } = await getRegistrationChallenge();

      const customerName = capitalizeSentence(`${currentUser.customerFirstName} ${currentUser.customerSurname}`);

      const options = {
        challenge: Base64.toUint8Array(challenge),
        rp: {
          id: new URL(getEnv(REACT_APP_LENDING_SELFIE_ENDPOINT)).hostname,
          name: 'Autogestion Credicuotas',
        },
        user: {
          id: Base64.toUint8Array(btoa(currentUser.customerTaxId)),
          name: customerName,
          displayName: customerName,
        },
        pubKeyCredParams: [{ alg: -7, type: 'public-key' }, { alg: -257, type: 'public-key' }],
        authenticatorSelection: {
          authenticatorAttachment: 'platform',
          userVerification: 'required',
        },
        timeout: 60000,
      };

      const credentialsResult = await navigator.credentials.create({ publicKey: options });

      const credentials = {
        id: credentialsResult.id,
        clientDataJSONEncoded: Base64.fromUint8Array(new Uint8Array(credentialsResult.response.clientDataJSON)),
      };
      await registerCredentials(credentials);
      showToast('Metodo de desbloqueo registrado exitosamente', 'success');
    } catch (error) {
      showToast('Ocurrió un error al intentar registrar el metodo de desbloqueo', 'error');
    } finally {
      setLoading(false);
    }
  };

  return {
    loading, register,
  };
};

export default useWebAuthnRegisterController;
