import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useLoginData } from '../../contexts/LoginDataContext';
import { useCustomerAccountStatus } from '../../contexts/CustomerAccountStatusContext';
import {
  removeCurrentUser, removeCustomerAccountStatus, saveCurrentLoginData, saveCurrentUser,
} from '../../api/userStorage';
import { setCredentials } from '../../api/authenticatePasswordApiService';
import { getPathRouteFromKey, PublicRoutesList } from '../../utils/menuAndRoutesUtil';
import { getError } from '../../utils/functionsUtil';
import { hasPermissionGrantedOrDenied } from '../../utils/firebase';
import i18n from '../../common/i18n';

const useGeneratePasswordController = () => {
  const history = useHistory();
  const { currentUser, setCurrentUser } = useAuth();
  const { setCurrentLoginData } = useLoginData();
  const { setCustomerAccountStatus } = useCustomerAccountStatus();
  const [loading, setLoading] = useState();
  const [responseSuccess, setResponseSuccess] = useState();
  const [responseError, setResponseError] = useState();

  const updateUserContext = () => {
    const currentUserCtx = currentUser;
    currentUserCtx.hasCredentials = true;
    setCurrentUser(currentUserCtx);
    saveCurrentUser(currentUserCtx);
  };

  const generatePassword = async (data) => {
    try {
      setLoading(true);
      const res = await setCredentials(data.newPass);
      updateUserContext();
      setResponseSuccess(res);
      if (currentUser.resetPassword) {
        // Seteo la info necesario para redirigir a la pantalla de login
        setCurrentLoginData(currentUser.loginData);
        saveCurrentLoginData(currentUser.loginData);

        // Borro datos del usuario logueado
        removeCustomerAccountStatus();
        setCustomerAccountStatus(undefined);
        removeCurrentUser();
        setCurrentUser(undefined);

        // Redirijo a la pantalla de login
        history.push({
          pathname: PublicRoutesList.loginPassword,
          state: { resetPassword: true },
        });
      }
    } catch (error) {
      setResponseError(getError(error));
    } finally {
      setLoading(false);
    }
  };

  const onContinue = () => {
    if (hasPermissionGrantedOrDenied()) {
      // Si ya acepto o rechazo recibir notificaciones va a la home
      window.location = getPathRouteFromKey(i18n.homeKey);
    } else {
      // En caso de que aun no haya aceptado o rechazado, va a la pantalla de notificaciones
      window.location = getPathRouteFromKey(i18n.pushNotificationSubscription);
    }
  };

  return {
    loading, generatePassword, responseSuccess, responseError, onContinue,
  };
};

export default useGeneratePasswordController;
