import { useState } from 'react';
import PropTypes from 'prop-types';
import '../../assets/css/headerStyle.css';

const Dropdown = ({ subMenu, dropDown }) => {
  const [dropDownExpanded, setDropdownExpanded] = useState(false);
  return (
    <ul
      className={`dropdown ${((dropDown) || (dropDownExpanded)) ? 'show' : ''}`}
      onMouseEnter={() => setDropdownExpanded((prev) => !prev)}
      onMouseLeave={() => setDropdownExpanded((prev) => !prev)}
    >
      {subMenu.map((item) => (
        <li key={item.title} className="menu-items dropdownbtn">
          <a
            href={item.path}
            className="headerTitles"
          >
            {item.text}
          </a>
        </li>
      ))}
    </ul>
  );
};

Dropdown.propTypes = {
  subMenu: PropTypes.objectOf(Object).isRequired,
  dropDown: PropTypes.bool.isRequired,
};

export default Dropdown;
