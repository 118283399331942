import { useEffect, useState } from 'react';
import { Box, makeStyles, useMediaQuery } from '@material-ui/core';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { useHistory } from 'react-router';
import i18n from '../common/i18n';
import CardOfferWrapper from './CardOfferWrapper';
import ClickeableBanner from './ClickeableBanner';
import imgRevolvingLineBanner from '../assets/imgs/revolving-line-banner.svg';
import { useAuth } from '../contexts/AuthContext';
import { useCustomerAccountStatus } from '../contexts/CustomerAccountStatusContext';
import { getPathRouteFromKey } from '../utils/menuAndRoutesUtil';

const useStyles = makeStyles(() => ({
  sliderBox: {
    height: '134px !important',
    maxWidth: 'auto',
    display: 'flex !important',
    alignItems: 'center',
    borderRadius: '12px',
    cursor: 'pointer',
    marginLeft: '5px',
    marginRight: '5px',
  },
}));

const BannerSlider = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:600px)');
  const history = useHistory();
  const { currentUser } = useAuth();
  const { customerAccountStatus } = useCustomerAccountStatus();
  const [bannerList, setBannerList] = useState([]);

  const styleImageWrapper = {
    backgroundImage: `url(${imgRevolvingLineBanner})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
  };

  const openRevolvingLineBannerLink = () => {
    history.push(getPathRouteFromKey(i18n.revolvingLineOffer));
  };

  const openCustomBannerLink = (banner) => {
    if (banner.link) {
      window.open(banner.link, '_blank');
    }
  };

  const showRevolvingLineOffer = () =>
    customerAccountStatus.customerRevolvingLine?.campaignOffer && customerAccountStatus.customerIsUpToDate;

  const showBannerOffer = () =>
    (currentUser.lastPendingLoan || customerAccountStatus.renewersCampaignOffer || customerAccountStatus.loanOffer)
    && customerAccountStatus.customerIsUpToDate;

  const showBannerMarketing = () =>
    currentUser.customBannerList && currentUser.customBannerList.length > 0;

  const getRevolvingLineBannerOffer = () => (
    <Box
      className={classes.sliderBox}
      onClick={openRevolvingLineBannerLink}
      style={styleImageWrapper}
    />
  );

  const getBannerOffer = () => (
    <Box className={classes.sliderBox}>
      <CardOfferWrapper />
    </Box>
  );

  const getBannerMarketing = (banner) => (
    <Box className={classes.sliderBox}>
      <ClickeableBanner
        onClick={() => openCustomBannerLink(banner)}
        imageUrl={isMobile ? banner.imageMobile : banner.image}
      />
    </Box>
  );

  const init = () => {
    const map = new Map();

    if (showBannerMarketing()) {
      currentUser.customBannerList.forEach((banner) => map.set(banner.index, getBannerMarketing(banner)));
    }
    if (showRevolvingLineOffer()) {
      map.set(10, getRevolvingLineBannerOffer());
    }
    if (showBannerOffer()) {
      map.set(20, getBannerOffer());
    }

    const list = [];
    const bannerKeyList = Array.from(map.keys()).sort();
    bannerKeyList.forEach((k) => list.push(map.get(k)));
    setBannerList(list);
  };

  useEffect(() => { init(); }, []);

  return (
    <>
      {bannerList.length > 0 && (
        <Box mt={3}>
          <Carousel
            swipeable
            showArrows={false}
            showStatus={false}
            centerMode={bannerList.length > 1}
            centerSlidePercentage={90}
          >
            {bannerList.map((banner) => banner)}
          </Carousel>
        </Box>
      )}
    </>
  );
};

export default BannerSlider;
