import {
  makeStyles, Typography,
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import { useAuth } from '../contexts/AuthContext';

const useStyles = makeStyles((theme) => ({
  backgroundAvatar: {
    backgroundColor: theme.palette.primary.main,
  },
  name: {
    fontSize: '0.9rem',
    fontFamily: 'Poppins',
    fontWeight: '700',
    textAlign: 'left',
  },
}));

const AvatarMenu = () => {
  const classes = useStyles();

  const { currentUser } = useAuth();
  const capitalLetters = currentUser?.customerFirstName.charAt(0) + currentUser?.customerSurname.charAt(0);
  return (
    <>
      <Grid container style={{ padding: '15px' }}>
        <Grid item xs={3}>
          <Avatar className={classes.backgroundAvatar}>
            <Typography
              style={{ fontSize: '1rem' }}
            >
              {capitalLetters}
            </Typography>

          </Avatar>
        </Grid>
        <Grid item xs={9}>
          <Typography
            component="h3"
            className={classes.name}
          >
            {currentUser?.customerFirstName}
            {' '}
            {currentUser?.customerSurname}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default AvatarMenu;
