import {
  BrowserRouter, Redirect, Route, Switch,
} from 'react-router-dom';
import AxiosInterceptor from '../api/AxiosInterceptor';
import { getCurrentLoginData, getCurrentUser, getCustomerAccountStatus } from '../api/userStorage';
import { AuthProvider } from '../contexts/AuthContext';
import { CustomerStatusProvider } from '../contexts/CustomerAccountStatusContext';
import { NotificationProvider } from '../contexts/NotificationContext';
import { LoginDataProvider } from '../contexts/LoginDataContext';
import LoginBiometricValidator from '../pages/login/loginBiometricValidator';
import LoginCodeNoReception from '../pages/login/loginCodeNoReception';
import LoginDNIValidation from '../pages/login/loginDNIValidation';
import LoginExpired from '../pages/login/loginExpired';
import LoginIdentityResolver from '../pages/login/loginIdentityResolver';
import UserNotClient from '../pages/login/loginUserNotClient';
import LoginVerificationCode from '../pages/login/loginVerificationCode';
import UpdateDebitCard from '../pages/updateDebitCard';
import { PublicRoutesList, RefinancingRoutesList } from '../utils/menuAndRoutesUtil';
import MainContent from './MainContent';
import PublicRoute from './PublicRoute';
import WikiComponents from '../pages/wiki/wikiComponents';
import Autologin from '../pages/login/autologin';
import { WIKI_AVAILABLE, getEnv } from '../api/env';
import RefinancingRouter from './RefinancingRouter';
import Splash from '../pages/prelogin/splash';
import InstallApp from '../pages/prelogin/installApp';
import { PreLoginProvider } from '../contexts/PreLoginContext';
import Introduction from '../pages/prelogin/introduction';
import LoginPassword from '../pages/login/loginPassword';

const AppRouter = () => {
  const loginData = getCurrentLoginData(); // recupera el loginData del localeStorage
  const userLogged = getCurrentUser(); // recupera el userLogged del localeStorage
  const customerAccountStatus = getCustomerAccountStatus();// recupera el customerAccountStatus del localeStorage

  const isWikiAvailable = () => getEnv(WIKI_AVAILABLE) === 'ON';

  return (
    <>
      <BrowserRouter>
        <LoginDataProvider loginData={loginData}>
          <AuthProvider user={userLogged}>
            <AxiosInterceptor />
            <NotificationProvider>
              <CustomerStatusProvider customerStatus={customerAccountStatus}>
                <PreLoginProvider>
                  <Switch>
                    <PublicRoute exact path={PublicRoutesList.splash}>
                      <Splash />
                    </PublicRoute>
                    <PublicRoute exact path={PublicRoutesList.installApp}>
                      <InstallApp />
                    </PublicRoute>
                    <PublicRoute exact path={PublicRoutesList.intro}>
                      <Introduction />
                    </PublicRoute>
                    <PublicRoute exact path={PublicRoutesList.loginDNIValidationStep}>
                      <LoginDNIValidation />
                    </PublicRoute>
                    <PublicRoute exact path={PublicRoutesList.loginIdentityResolverStep}>
                      <LoginIdentityResolver />
                    </PublicRoute>
                    <PublicRoute exact path={PublicRoutesList.loginPassword}>
                      <LoginPassword />
                    </PublicRoute>
                    <PublicRoute exact path={PublicRoutesList.loginVerificationCodeStep}>
                      <LoginVerificationCode />
                    </PublicRoute>
                    <PublicRoute exact path={PublicRoutesList.loginCodeNoReception}>
                      <LoginCodeNoReception />
                    </PublicRoute>
                    <PublicRoute exact path={PublicRoutesList.loginUserNotClient}>
                      <UserNotClient />
                    </PublicRoute>
                    <PublicRoute exact path={PublicRoutesList.loginChangeCellphoneNumber}>
                      <LoginBiometricValidator />
                    </PublicRoute>
                    <PublicRoute exact path={PublicRoutesList.loginExpired}>
                      <LoginExpired />
                    </PublicRoute>
                    <PublicRoute exact path={PublicRoutesList.updateDebitCard}>
                      <UpdateDebitCard />
                    </PublicRoute>
                    {isWikiAvailable() && (
                      <PublicRoute exact path={PublicRoutesList.wikiComponents}>
                        <WikiComponents />
                      </PublicRoute>
                    )}
                    <PublicRoute exact path={PublicRoutesList.autologin}>
                      <Autologin />
                    </PublicRoute>
                    <Route path={RefinancingRoutesList.home}>
                      <RefinancingRouter />
                    </Route>
                    <MainContent />
                    <Route>
                      <Redirect from="*" to={PublicRoutesList.welcome} />
                    </Route>
                  </Switch>
                </PreLoginProvider>
              </CustomerStatusProvider>
            </NotificationProvider>
          </AuthProvider>
        </LoginDataProvider>
      </BrowserRouter>
    </>
  );
};
export default AppRouter;
