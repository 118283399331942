/* eslint-disable jsx-a11y/media-has-caption */
import { useEffect, useRef, useState } from 'react';
import {
  Grid, Typography,
} from '@material-ui/core';
import QrScanner from 'qr-scanner';
import '../assets/css/qrStyle.css';
import QrFrame from '../assets/imgs/qr-frame.svg';
import { getPaymentInfoQR } from '../api/selfieWebService';

const QRCodeScanner = () => {
  const scanner = useRef();
  const videoEl = useRef();
  const qrBoxEl = useRef();
  const [qrOn, setQrOn] = useState(true);
  const [scannedResult, setScannedResult] = useState(undefined);
  const [infoQR, setInfoQR] = useState(undefined);

  const onScanSuccess = async (result) => {
    console.log(result);
    setScannedResult(result?.data);
    const response = await getPaymentInfoQR(result?.data);
    setInfoQR(response.data);
  };

  const onScanFail = (err) => {
    console.log(err);
  };

  useEffect(() => {
    if (videoEl?.current && !scanner.current) {
      // Instantiate the QR Scanner
      scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
        onDecodeError: onScanFail,
        // This is the camera facing mode. In mobile devices, "environment"
        // means back camera and "user" means front camera.
        preferredCamera: 'environment',
        // This will help us position our "QrFrame.svg" so that user can only scan
        // when qr code is put in between our QrFrame.svg.
        highlightScanRegion: true,
        // This will produce a yellow (default color) outline around the qr code that we scan,
        // Showing a proof that our qr-scanner is scanning that qr code.
        highlightCodeOutline: true,
        // A custom div which will pair with "highlightScanRegion" option above 👆.
        // This gives us full control over our scan region.
        overlay: qrBoxEl?.current || undefined,
      });
      // Start QR Scanner
      scanner?.current
        ?.start()
        .then(() => setQrOn(true))
        .catch((err) => {
          if (err) setQrOn(false);
        });
    }
    // Clean up on unmount.
    // This removes the QR Scanner from rendering and using camera when it is closed or removed from the UI.
    return () => {
      if (!videoEl?.current) {
        // Stop QR Scanner
        scanner?.current?.stop();
      }
    };
  }, []);

  return (
    <>
      {qrOn ? (
        <>
          {scannedResult ? (
            <>
              {/* QR INFO */}
              {infoQR && (
              <Grid container>
                <Grid item xs={12}>
                  <Typography>
                    {`status: ${infoQR.status}`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    {`name: ${infoQR.collector?.name}`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    {`identificationNumber: ${infoQR.collector?.identificationNumber}`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    {`account: ${infoQR.collector?.account}`}
                  </Typography>
                </Grid>
              </Grid>
              )}
            </>
          ) : (
            // QR SCANNER
            <div className="qr-reader">
              <video ref={videoEl} />
              <div ref={qrBoxEl} className="qr-box">
                <img src={QrFrame} alt="Qr Frame" className="qr-frame" />
              </div>
            </div>
          )}
        </>
      ) : (
        <Typography>
          Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload.
        </Typography>
      )}
    </>
  );
};

export default QRCodeScanner;
