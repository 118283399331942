import PropTypes from 'prop-types';
import {
  Box,
  Button, Grid, Hidden, makeStyles, Typography,
} from '@material-ui/core';
import i18n from '../common/i18n';
import { moneyFormatter } from '../utils/formatterUtil';

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: '#FFDCE9',
    padding: '15px',
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: '1rem',
    fontFamily: 'Poppins',
    textAlign: 'left',
  },
  subtitle: {
    fontSize: '1rem',
    textAlign: 'left',
  },
}));

const CardCustomerDebtor = ({
  totalInstallmentDebt,
  totalPunitiveDebt,
  totalDebt,
  onClickPay,
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.card}>
        <Grid item xs={12} md={6}>
          <Typography className={classes.title}>
            <b>
              {i18n.customerDebtorTotalDebt}
              {moneyFormatter(totalDebt)}
            </b>
            <br />
            {i18n.customerDebtorIncludes}
          </Typography>
        </Grid>
        <Hidden smDown>
          <Grid item md={6} style={{ textAlign: 'end' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={onClickPay}
            >
              {i18n.customerDebtorLabelPayButton}

            </Button>
          </Grid>
        </Hidden>
        <Box m={1} />
        <Grid item xs={12} md={12} className={classes.subtitle}>
          <Typography>
            <b>{i18n.customerDebtorDetails}</b>
          </Typography>
          <Typography style={{ marginRight: '20px' }}>
            {i18n.customerDebtorResidue}
            <b>{moneyFormatter(totalInstallmentDebt)}</b>
          </Typography>
          <Typography style={{ marginRight: '20px' }}>
            {i18n.customerDebtorPunitive}
            <b>{moneyFormatter(totalPunitiveDebt)}</b>
          </Typography>
          <Typography style={{ marginRight: '20px' }}>
            {i18n.customerDebtorBalance}
            <b>{moneyFormatter(totalDebt)}</b>
          </Typography>
        </Grid>
        <Hidden smUp>
          <Box m={1} />
          <Grid item xs={12} style={{ textAlign: 'initial' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={onClickPay}
            >
              {i18n.customerDebtorLabelPayButton}
            </Button>
          </Grid>
        </Hidden>
      </Grid>
    </>
  );
};

CardCustomerDebtor.propTypes = {
  totalInstallmentDebt: PropTypes.number.isRequired,
  totalPunitiveDebt: PropTypes.number.isRequired,
  totalDebt: PropTypes.number.isRequired,
  onClickPay: PropTypes.func.isRequired,
};

export default CardCustomerDebtor;
