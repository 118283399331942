import fetch from './api';
import { API_SELFIE, getEnv } from './env';

const urlBackend = getEnv(API_SELFIE);

export const validateSession = () =>
  fetch({
    baseURL: `${urlBackend}`,
    url: '/customers/validateSession',
    method: 'get',
  });

export const getUsers = (dni) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/users/${dni}`,
    method: 'get',
  });

export const getLastLoan = (customerTaxId, statusList = 'ACT,CAN') =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/users/${customerTaxId}/lastLoan`,
    params: {
      statusList,
    },
    method: 'get',
  });

export const getLastPendingLoan = (customerTaxId) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/users/${customerTaxId}/lastPendingLoan`,
    method: 'get',
  });

export const sendVerificationCode = (customerTaxId, hash, sendByEmail) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/users/${customerTaxId}/verificationCode/sendSMS`,
    params: { hash, sendByEmail },
    method: 'get',
  });

export const sendVerificationCodeNewPhone = (customerTaxId, hash, phone) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/users/${customerTaxId}/verificationCode/sendSMS`,
    params: { hash, phone },
    method: 'get',
  });

export const authenticateCustomer = (customerTaxId, smsCode, resetPassword) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/users/${customerTaxId}/authenticateCustomer`,
    method: 'post',
    data: {
      smsCode,
      resetPassword,
    },
  });

export const findActiveLoansByCustomerTaxId = ({
  customerTaxId,
  status = 'ACTIVE',
}) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/customers/${customerTaxId}/loans`,
    params: { status },
    method: 'get',
  });

export const findRevolvingLineByCustomerTaxId = (customerTaxId) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/customers/${customerTaxId}/revolvingLine`,
    method: 'get',
  });

export const findActiveLoansByCustomerTaxIdWithDocuments = ({
  customerTaxId,
  status = 'ACTIVE',
  expandedResponseTypes = ['INSTALLMENT', 'LOAN_FILE'],
  expandedResources = expandedResponseTypes.join(','),
}) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/customers/${customerTaxId}/loans`,
    params: { status, expandedResources },
    method: 'get',
  });

export const findActiveLoansByCustomerTaxIdAndExcludeRevolvingLine = ({
  customerTaxId,
  status = 'ACTIVE',
  excludeProducts = '46',
}) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/customers/${customerTaxId}/loans`,
    params: { status, excludeProducts },
    method: 'get',
  });

export const findActiveLoansByCustomerTaxIdWithDocumentsAndExcludeRevolvingLine = ({
  customerTaxId,
  status = 'ACTIVE',
  expandedResponseTypes = ['INSTALLMENT', 'LOAN_FILE'],
  expandedResources = expandedResponseTypes.join(','),
  excludeProducts = '46',
}) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/customers/${customerTaxId}/loans`,
    params: { status, expandedResources, excludeProducts },
    method: 'get',
  });

export const findFinishedLoansByCustomerTaxId = ({
  customerTaxId,
  status = 'FINISHED',
}) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/customers/${customerTaxId}/loans`,
    params: { status },
    method: 'get',
  });

export const getNewLoanOffer = (customerId, product, subproduct) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/customers/${customerId}/getNewLoanOffer`,
    params: { product, subproduct },
    method: 'get',
  });

export const resolveNewLoanOfferAutomaticIfApplies = (customerId, product, subproduct) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/customers/${customerId}/resolveNewLoanOfferAutomaticIfApplies`,
    params: { product, subproduct },
    method: 'get',
  });

export const getLoanRequest = (hash) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/customers/loanRequest/${hash}`,
    method: 'get',
  });

export const getLastLoanRequest = (
  customerTaxId,
  product,
  subProduct,
) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/customers/${customerTaxId}/getLastLoanRequest`,
    params: { product, subProduct },
    method: 'get',
  });

export const getInvoices = (customerId) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/customers/${customerId}/invoices`,
    method: 'get',
  });

export const getInvoiceFile = (customerId, invoiceId) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/customers/${customerId}/invoices/${invoiceId}`,
    method: 'get',
    responseType: 'blob',
  });

export const getLoanFileFromTypeFile = (customerTaxId, externalId, fileType) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/customers/${customerTaxId}/loans/${externalId}/file/${fileType}`,
    method: 'get',
    responseType: 'blob',
  });

export const createCRMTicket = (customerTaxId, dto) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/customers/${customerTaxId}/crmTickets/`,
    method: 'post',
    data: {
      customertTaxId: dto.customerTaxId,
      reasonId: dto.typeClaimSelect,
      message: dto.comments,
      customerEmail: dto.email,
    },
  });

export const getCRMReasons = () =>
  fetch({
    baseURL: `${urlBackend}`,
    url: '/customers/crmTickets/reasons',
    method: 'get',

  });

export const getPaymentVoucherFile = (customerId, externalId) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/customers/${customerId}/loans/${externalId}/paymentVoucher`,
    method: 'get',
    responseType: 'blob',
  });

export const getFreeDebtFile = (customerId) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/customers/${customerId}/freeDebt`,
    method: 'get',
    responseType: 'blob',
  });

export const getCustomerAccountStatusByTaxId = (customerTaxId) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/customers/${customerTaxId}/accountStatus`,
    method: 'get',
  });

export const getPaymentVoucherTypeList = () =>
  fetch({
    baseURL: `${urlBackend}`,
    url: '/customers/paymentVoucherType',
    method: 'get',
  });

export const sendPaymentNotification = (customerTaxId, postData) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/customers/${customerTaxId}/paymentNotification`,
    method: 'post',
    data: postData,
    headers: {
      'content-type': 'multipart/form-data',
    },
  });

export const getCustomerEditableData = (customerId) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/customers/${customerId}/editableData`,
    method: 'get',
  });

export const getStateList = () =>
  fetch({
    baseURL: `${urlBackend}`,
    url: '/locations/states',
    method: 'get',
  });

export const getDistrictList = (state) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/locations/states/${state}/districts`,
    method: 'get',
  });

export const getCityList = (state) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/locations/states/${state}/cities`,
    method: 'get',
  });

export const updateCustomerContactData = (customerId, postData) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/customers/${customerId}/contactData`,
    method: 'put',
    data: postData,
  });

export const updateCustomerLocationData = (customerId, postData) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/customers/${customerId}/locationData`,
    method: 'put',
    data: postData,
  });

export const getPaymentVoucherPdf = (customerTaxId, amount) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/customers/${customerTaxId}/payments/voucher?amount=${amount}`,
    method: 'get',
    responseType: 'blob',
  });

export const getActiveLoansWithMoreDaysPastDue = (customerTaxId) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/customers/${customerTaxId}/loans/debt`,
    method: 'get',
  });

export const getDebitCardStatus = (debitCardToken) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/users/debitCardStatus/${debitCardToken}`,
    method: 'get',
  });

export const updateDebitCardData = (debitCardToken, newDebitCardData) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/users/debitCardUpdate/${debitCardToken}`,
    method: 'put',
    data: newDebitCardData,
  });

export const updateCellphone = (customerTaxId, updateCellphoneData) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/users/${customerTaxId}/updateCellphone`,
    method: 'put',
    data: updateCellphoneData,
  });

export const generateBiometricOnboardingData = (referenceId, redirectPath) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/users/generateBiometricOnboardingData/${referenceId}`,
    method: 'get',
    params: { redirectPath },
  });

export const validateBiometricResult = (customerTaxId, externalId) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/users/${customerTaxId}/validateBiometricResult/${externalId}`,
    method: 'get',
  });
export const getPaymentInformation = (externalId) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/payments/loans/${externalId}`,
    method: 'get',
  });

export const debitCardPayment = (idCredit, paymentInformation) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/payments/cardPayment/${idCredit}`,
    method: 'post',
    data: paymentInformation,
  });

export const mercadoPagoPayment = (idCredit, amount) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/payments/mercadoPagoPayment/${idCredit}`,
    method: 'post',
    data: { amount },
  });

export const getQrCodePayment = (idCredit) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/payments/qrCodePayment/${idCredit}`,
    method: 'get',
  });

export const getCvuPayment = (idCredit) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/payments/cvuPayment/${idCredit}`,
    method: 'get',
  });

export const getPaymentInfoQR = (qrText) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: '/payments/getPaymentInfoQR',
    method: 'get',
    params: { qrText },
  });

export const getLoanProductDetails = (idCredit) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `payments/productDetails/${idCredit}`,
    method: 'get',
  });

export const getPrePaidLoanInformation = (idCredit) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/payments/prePaidLoanInformation/${idCredit}`,
    method: 'get',
  });

export const generatePrePaidLoan = (idCredit, amount, user) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/payments/generatePrePaidLoan/${idCredit}`,
    method: 'post',
    data: { amount, user },
  });

export const downloadPrePaidLoanDocument = (idCredit, amount, user) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/payments/downloadPrePaidLoanDocument/${idCredit}`,
    method: 'post',
    data: { amount, user },
    responseType: 'blob',
  });

export const getPrepaidCardStatus = (customerTaxId) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/prepaidcard/${customerTaxId}/getPrepaidCardStatus`,
    method: 'get',
  });

export const getPrepaidCardSecuredInfoUrl = (customerTaxId) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/prepaidcard/${customerTaxId}/prepaidCardSecuredInfoUrl`,
    method: 'get',
  });

export const getPrepaidCardSecuredActivationUrl = (customerTaxId) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/prepaidcard/${customerTaxId}/prepaidCardSecuredActivationUrl`,
    method: 'get',
  });

export const getPrepaidCardInfo = (customerTaxId) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/prepaidcard/${customerTaxId}/prepaidCardInfo`,
    method: 'get',
  });

export const pausePrePaidCard = (customerTaxId) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/prepaidcard/${customerTaxId}/prepaidCardPause`,
    method: 'post',
  });

export const unpausePrePaidCard = (customerTaxId) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/prepaidcard/${customerTaxId}/prepaidCardUnpause`,
    method: 'post',
  });

export const disablePrePaidCard = (customerTaxId, disableReason) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/prepaidcard/${customerTaxId}/prepaidCardDisable/${disableReason}`,
    method: 'post',
  });

export const changePinPrePaidCard = (customerTaxId, pin) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/prepaidcard/${customerTaxId}/prepaidCardChangePin`,
    params: { pin },
    method: 'post',
  });

export const reprintPrePaidCard = (customerTaxId, data) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/prepaidcard/${customerTaxId}/reprintPrePaidCard`,
    method: 'post',
    data,
  });

export const proccessResultActivation = (customerTaxId) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/prepaidcard/${customerTaxId}/proccessResultActivation`,
    method: 'post',
  });

export const autologin = (data) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/users/autologin?data=${data}`,
    method: 'post',
  });

export const generateAutologinToken = (customerTaxId, redirectTo) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/customers/${customerTaxId}/generateAutologinToken`,
    params: { redirectTo },
    method: 'get',
  });

export const getSurvey = () =>
  fetch({
    baseURL: `${urlBackend}`,
    url: '/nps',
    method: 'get',
  });

export const surveyAlreadyAnswered = () =>
  fetch({
    baseURL: `${urlBackend}`,
    url: '/nps/answered',
    method: 'get',
  });

export const saveSurveyAnswer = (data) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: '/nps',
    method: 'post',
    data,
  });

export const skipSurvey = (customerId) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: '/nps/skip',
    method: 'post',
    data: {
      userId: customerId,
    },
  });

export const getNotifications = () =>
  fetch({
    baseURL: urlBackend,
    url: '/customers/notifications',
    method: 'get',
  });

export const markAsReadNotification = (id) =>
  fetch({
    baseURL: urlBackend,
    url: `/customers/notifications/${id}`,
    method: 'post',
  });

export const getPaymentsDetails = (customerId) =>
  fetch({
    baseURL: urlBackend,
    url: `/customers/paymentsDetails/${customerId}`,
    method: 'get',
  });

export const getPrepaidBenefit = () =>
  fetch({
    baseURL: urlBackend,
    url: '/customers/prepaidBenefit',
    method: 'get',
  });

export const getCustomBanner = () =>
  fetch({
    baseURL: urlBackend,
    url: '/customers/getCustomBanner',
    method: 'get',
  });

export const getInstallmentPlan = (customerTaxId, amount) =>
  fetch({
    baseURL: urlBackend,
    url: `/customers/${customerTaxId}/installmentPlan/${amount}`,
    method: 'get',
  });

export const revolvineLineCreateLoanRequest = (customerTaxId, data) =>
  fetch({
    baseURL: urlBackend,
    url: `/customers/${customerTaxId}/revolvingLine/start`,
    method: 'post',
    data,
  });

export const getDetailLoanCost = (loanId) =>
  fetch({
    baseURL: urlBackend,
    url: `/users/loanRequest/summary/${loanId}`,
    method: 'get',
  });

export const getPrepaidCardLastDigits = (customerTaxId) =>
  fetch({
    baseURL: urlBackend,
    url: `/prepaidcard/${customerTaxId}/prepaidCardLastDigits`,
    method: 'get',
  });

export const getAgreementDocument = (loanId) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/users/loans/${loanId}/agreement`,
    method: 'get',
    responseType: 'blob',
  });

export const disburseLoan = (customerTaxId, loanId) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/customers/${customerTaxId}/loans/${loanId}/disbursement`,
    method: 'put',
  });

export const getRevolvingLineNexExpirationDetail = (customerTaxId) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/customers/${customerTaxId}/revolvingLine/nextExpirationDetail`,
    method: 'get',
  });

export const getMyPaymentList = (customerTaxId) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/customers/${customerTaxId}/payments`,
    method: 'get',
  });

export const getPaymentMethodActiveByCuil = (customerTaxId, externalLoanId) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/customers/${customerTaxId}/loans/${externalLoanId}`,
    method: 'get',
  });

export const performLoginUsingPassword = (customerTaxId, password) =>
  fetch({
    baseURL: `${urlBackend}`,
    url: `/users/${customerTaxId}/login`,
    method: 'post',
    data: password,
  });
