import { useEffect, useState } from 'react';
import { hasPermissionGrantedOrDenied, requestPermission } from '../utils/firebase';
import useHomeNavigation from './useHomeNavigation';
import { useToaster } from '../contexts/ToasterContext';
import i18n from '../common/i18n';
import { useAuth } from '../contexts/AuthContext';
import { saveCurrentUser } from '../api/userStorage';

const usePushNotificationSubscription = () => {
  const goToHome = useHomeNavigation();
  const { currentUser, setCurrentUser } = useAuth();
  const { showToast } = useToaster();

  const [globalLoading, setGlobalLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const init = () => {
    if (hasPermissionGrantedOrDenied()) {
      goToHome();
    } else {
      setGlobalLoading(false);
    }
  };

  const subscribe = async () => {
    try {
      setLoading(true);
      await requestPermission(currentUser.customerId);
      goToHome();
    } catch (error) {
      showToast(i18n.PushNotificationSubscription.errorSubscription);
    } finally {
      setLoading(false);
    }
  };

  const omit = () => {
    const currentUserAux = currentUser;
    currentUserAux.skipNotification = true;
    // guardo en el contexto y localStorage que omitio para que no le vuelva a
    // aparecer hasta el proximo login hasta que no haya aceptado o rechazado recibir notificaciones
    setCurrentUser(currentUserAux);
    saveCurrentUser(currentUserAux);
    goToHome();
  };

  useEffect(init, []);

  return {
    globalLoading, loading, subscribe, omit,
  };
};

export default usePushNotificationSubscription;
